import React from "react"
import { graphql} from 'gatsby'
import Layout from "../components/layout"
import { Container, Row, Col } from 'reactstrap'
import Breadcrumb  from "../components/breadcrumb"
import styled from 'styled-components'
import Image from "gatsby-image"
import useSiteMetadata from '../hooks/use-site-config'
import { Helmet } from "react-helmet"

import Link from '../components/link'


export const query = graphql`
query {
 
  cipeImag: file(relativePath: { eq: "assets/images/app/cipeImag.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  dni: file(relativePath: { eq: "assets/images/app/dni.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`


const CellImage = styled(Image)`
width: 300px;

  `
  
  var style = {
    marginTop: '100px'
  };


export default function SmsApp(props) {
  
  const { siteTitle } = useSiteMetadata()

  const cipeImag = props.data.cipeImag.childImageSharp.fluid;
  const dni = props.data.dni.childImageSharp.fluid;
  console.log(props)
  const pageContext= props.pageContext;
  return (
    <Layout >
      <Helmet>
          <meta charSet="utf-8" />
           <title>¿Cómo obtengo mi código? | {siteTitle}</title>
        </Helmet>
      <Container >
        <h1 className="text-center m-4">Seleccione como quiere obtener su codigo:</h1>
        <Row className="justify-content-center">
       
        <Col xs="12" sm="6" md="6" >
        <h2 className="text-center">Con CIPE</h2>
        <Link to={props.path+"cipe"}>
        <CellImage className="center" fluid={cipeImag}></CellImage>
        </Link>
        </Col>
        
        <Col xs="12" sm="6" md="6" >
        <h2 className="text-center">Con DNI</h2>
        <Link to={props.path+"dni"}>
        <CellImage className="center" fluid={dni}></CellImage>
        </Link>
        </Col>
        </Row>
      </Container>
      
      <Breadcrumb pageContext={props.pageContext}  style={style} label="¿Cómo obtengo mi código?" />

    </Layout>
  )
}
